<template>
  <div>
    <el-cascader
        style="width: 300px"
        ref="deptCascader"
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        placeholder="搜索：项目*部 / 项目*组"
        :options="options"
        :multiple="multiple"
        :size="size"
        :props="{ value: 'dpt_id',label:'name',children:'groups',checkStrictly: checkStrictly,multiple: multiple,emitPath:emitPath }"
        filterable clearable @change="handleDeptLabels"></el-cascader>
  </div>
</template>

<script>
export default {
  name: "DeptCascader",
  props: {
    showFirstGroup: {
      type: Boolean,
      default() {
        return false
      }
    },
    currentGroups: {
      type: [Array, String],
      default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    emitPath: {
      type: Boolean,
      default() {
        return true
      }
    },
    checkStrictly: {
      type: Boolean,
      default() {
        return false
      }
    },
    selectUrl: {
      type: String,
      default() {
        return 'getMcnGroupDeptOption'
      }
    }
    ,
    size: {
      type: String,
      default() {
        return 'small'
      }
    }

  },
  data() {
    return {
      value: null,
      dpt_id: null,
      options: []
    };
  },
  watch: {
    currentGroups(val, oldVal) {
      if (val !== oldVal)
        this.value = val
    },
  },
  methods: {
    async getOptions() {
      let data = await this.$api[this.selectUrl]()
      this.options = data?.list ? data.list : data

      if (this.showFirstGroup)
        this.firstGroup()
    },
    firstGroup() {
      if (this.options && this.options.length > 0) {
        if (this.options[0]['groups']) {
          let group = this.options[0]['groups'][0]
          this.$emit('firstGroup', group)
          this.value = group.dpt_id
        }
      }
    },
    initVal(val) {
      this.value = val
    },
    //获取label值
    handleDeptLabels(valArr) {
      let labelArr = []
      if (valArr && valArr.length > 0) {
        labelArr = this.$refs['deptCascader'].getCheckedNodes()[0].pathLabels
      }
      this.$emit('selectedDept', valArr, labelArr)
    },

  },
  created() {
    this.getOptions()
  }
}
</script>

<style scoped>

</style>
